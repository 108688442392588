import {createAsyncThunk} from '@reduxjs/toolkit'
import {toast as message} from 'react-toastify'
import {getApi, patchApi, postApi} from '../../../axios'
import {API_ERROR} from '../../../global'
import {PREORDERTAX, TRANSACTIONLIST} from '../../../axios/services'

export const getTransactionlist = createAsyncThunk(
  'tsc/getTransactionlist',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(
        `${TRANSACTIONLIST}?got_created=${payload?.created || ''}&page=${
          payload?.page || 1
        }&items_per_page=10`
      )
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const createBillingtax = createAsyncThunk(
  'tsc/createBillingtax',
  async ({body, next = () => {}}, thunkAPI) => {
    console.log(body,'llllllllllllllllllllllllll')
    try {
      const response = await postApi(PREORDERTAX, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)



export const patchBillingtax = createAsyncThunk(
  'tsc/patchBillingtax',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(PREORDERTAX, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)



export const getBillingtax = createAsyncThunk(
  'tsc/getBillingtax',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(`${PREORDERTAX}`) 
      if (response?.data?.success) {
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
