export const BASEURL =
    // 'https://devapi.healon.ai/'
//  'https://api.healon.ai/' 
// 'http://127.0.0.1:8000/'
//  'http://127.0.0.1//"
//   'https://devapi.healon.in/' 
  'https://api.healon.in/' 


//"https://backoffice.healon.care/";

export const EXTERNAL_BASEURL = ''

export const UPLOAD_IMAGE = ''

export const CART = 'findcare/add-items-cart'

export const ADDRESS = 'findcare/customer-address'
export const USER_DETAILS = 'userdetails/?user_type='
export const ADD_MEMBER = 'findcare/add-member'
export const PARTNER_CASES = 'findcare/partner-cases'
export const PARTNER_DETAILS = 'partner/partner-details'
export const PARTNER_STATUS = '/user-state'
export const PARTNER_PREFERENCE = '/partner/partner-casepreferences'

// ----------------------------------- CATALOG ----------------------------------------------------------------
export const PACKAGE = 'findcare/standard-package'
export const CUSTOMPACKAGELIST = 'findcare/CustomPackage-List'
export const CREATECUSTOMPACKAGE = 'findcare/create-custompackage/'
export const PACKAGE_DETAILS = 'findcare/package-details?package_id='
export const CREATE_PACKAGE = 'findcare/create-packages'
export const SERVICE_TYPES = 'findcare/package-service-location?id='
export const SERVICE_TYPE = 'findcare/healon-services-types?id='
export const PACKAGE_CATEGORY = 'findcare/package-category'
export const PRODUCTS = 'shop/create-product'
export const VARIATION = 'shop/product-variation'
export const PRODUCT_CATEGORY = 'shop/product-category'
export const GETALL_USER = 'users/'


// ----------------------------------- OMS ----------------------------------------------------------------
export const CALLBACKREQUEST = 'findcare/Callback-requestlist'
export const CALLBACK = 'findcare/Callback/'
export const ORDERS = 'findcare/orders'
export const CREATE_ORDER = 'findcare/create-order'
export const PARTNERS = 'findcare/get-partners'
export const SHOP_ORDERS = 'shop/product-orders-list'
export const PATCH_SHOP_ORDERS = 'shop/product-orders-create'



// ----------------------------------- USER_MANAGEMENT ----------------------------------------------------------------
export const USER_LIST = 'user-management'
export const SIGN_UP_DISCOVER = 'findcare/signup?user_role='
export const SET_PASSWORD = 'set-new-password/'

// ----------------------------------- EMPLOYERS ----------------------------------------------------------------
export const JOBS = 'nurse/job/'
export const APPLICATION = 'coms/applications'
export const EMPLOYER_REPORT = 'coms/employer-reports'
export const EMPLOYERS = 'coms/employer-list'
// ----------------------------------- CUSTOMERS_MANAGEMENT ----------------------------------------------------------------

// ----------------------------------- HPMS ----------------------------------------------------------------
export const HPMS_REPORTS = 'coms/reports?user_role=nurse&verification_status='
export const BGV_REQUESTS = 'coms/bgv-requests'
export const USER_STATE = 'user-state'
// ----------------------------------- HPPMS ----------------------------------------------------------------
export const HPPMS_REPORTS = 'coms/partner-reports'

// ----------------------------------- CMS ----------------------------------------------------------------
export const CMS_REPORTS = 'coms/customer-reports'
export const CUSTOMERS = 'coms/customer-list'

// ----------------------------------- CMS ----------------------------------------------------------------

export const VENDOR_PARTNERS = 'shop/vendor-partners'

// ----------------------------------- TSC ----------------------------------------------------------------

export const TRANSACTIONLIST = 'findcare/transcation-list'
export const PREORDERTAX = 'findcare/Create-preordertax'



