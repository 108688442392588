/* eslint-disable react/jsx-no-target-blank */
import {FC} from 'react'
// import {KTIcon} from '../../../../helpers'
// import {AuthorsTab} from './AuthorsTab'
import {CatalogTab} from './CatalogTab'
import {EmployersTab} from './EmployersTab'
import {HPMSTab} from './HPMSTab'
import {HPPMSTab} from './HPPMSTab'
import {NotificationsTab} from './NotificationsTab'
import {OMSTab} from './OMSTab'
// import { ProjectsTab } from './ProjectsTab'
import {UsersTab} from './UsersTab'
import {CMSTab} from './CMSTab'
import {VPMSTab} from './VPMSTab'
import  {TSCTab} from './TSCTab'
import { BILLING } from './BILLINGTab'
type Props = {
  link: string
}

const SelectedTab: FC<Props> = ({link}) => {
  switch (true) {
    case link?.includes('oms'):
      return <OMSTab />
    case link?.includes('hpms'):
      return <HPMSTab />
    case link?.includes('hppms'):
      return <HPPMSTab />
    case link?.includes('cms'):
      return <CMSTab />
    case link?.includes('employers'):
      return <EmployersTab />
    case link?.includes('catalogs'):
      return <CatalogTab />
    case link?.includes('notifications'):
      return <NotificationsTab />
    case link?.includes('users'):
      return <UsersTab />
    case link?.includes('vpms'):
      return <VPMSTab />
    case link?.includes('transaction'):
        return <TSCTab />
    case link?.includes('billing'):
          return <BILLING />
    default:
      return <OMSTab />
  }
}

const TabsBase: FC<Props> = ({link}) => {
  return (
    <div className='d-flex h-100 flex-column'>
      {/* begin::Wrapper */}
      <div
        className='flex-column-fluid hover-scroll-y'
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        data-kt-scroll-height='auto'
        data-kt-scroll-wrappers='#kt_aside_wordspace'
        data-kt-scroll-dependencies='#kt_aside_secondary_footer'
        data-kt-scroll-offset='0px'
      >
        {/* begin::Tab content */}
        <div className='tab-content'>
          <div
            className='tab-pane fade active show'
            id={`kt_aside_nav_tab_${link}`}
            role='tabpanel'
          >
            <SelectedTab link={link} />
          </div>
        </div>
        {/* end::Tab content */}
      </div>
      {/* end::Wrapper */}
      {/* begin::Footer */}
      <div className='flex-column-auto pt-10 px-5' id='kt_aside_secondary_footer'>
        {/* <a
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          target='_blank'
          className='btn btn-bg-light btn-color-gray-600 btn-flex btn-active-color-primary flex-center w-100'
          data-bs-toggle='tooltip'
          data-bs-custom-class='tooltip-dark'
          data-bs-trigger='hover'
          data-bs-offset='0,5'
          data-bs-dismiss-='click'
        >
          <span className='btn-label'>Docs & Components</span>
          <KTIcon iconName='document' className='btn-icon fs-4 ms-2' />
        </a> */}
      </div>
      {/* end::Footer */}
    </div>
  )
}

export {TabsBase}
