/* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  // const intl = useIntl()
  const authData = JSON.parse(localStorage.getItem('auth') || 'null')
  const roles = authData?.role?.split(',').map((role: string) => role.trim()) || []

  const isSuperUser = roles.includes('superuser')
  const isSellerOrPartner = roles.some(role => ['seller-vp', 'partner'].includes(role))

   if (isSellerOrPartner) {
    return (
      <>
      <AsideMenuItem
        to='/catalogs'
        icon='color-swatch'
        title={'Catalog Management'}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Setup</span>
        </div>
      </div>

      <AsideMenuItemWithSub to='/catalogs' title='Care Packages' hasBullet={false}></AsideMenuItemWithSub>
        <AsideMenuItem
          to='/catalogs/packages'
          title={<div>Packages</div>}
          hasBullet={true}
        />
        <AsideMenuItem
          to='/catalogs/custompackages'
          title={<div>Custom Packages</div>}
          hasBullet={true}
        />
        <AsideMenuItemWithSub to='/catalogs/products' title='Products' hasBullet={false}>
          <AsideMenuItem to='/catalogs/products' title='Products' hasBullet={true} />
        </AsideMenuItemWithSub>
      </>
    )
  }

  return (
    <>
      <AsideMenuItem
        to='/catalogs'
        icon='color-swatch'
        title={'Catalog Management'}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Setup</span>
        </div>
      </div>

      <AsideMenuItemWithSub to='/catalogs' title='Care Packages' hasBullet={false}>
        <AsideMenuItem
          to='/catalogs/care-types'
          title={
            <div>
              Care Type{' '}
              <span className='d-inline-block' data-toggle='tooltip' title='L1 Categories'>
                <i className='bi bi-info-circle-fill'></i>
              </span>
            </div>
          }
          hasBullet={true}
        />
        <AsideMenuItem
          to='/catalogs/care-categories'
          title={
            <div>
              Care Categories{' '}
              <span className='d-inline-block' data-toggle='tooltip' title='L2 Categories'>
                <i className='bi bi-info-circle-fill'></i>
              </span>
            </div>
          }
          hasBullet={true}
        />
        <AsideMenuItem
          to='/catalogs/packages'
          title={
            <div>
              Packages{' '}
              {/* <span className='d-inline-block' data-toggle='tooltip' title='L3 Packages'>
                <i className='bi bi-info-circle-fill'></i>
              </span> */}
            </div>
          }
          hasBullet={true}
        />
        <AsideMenuItem
          to='/catalogs/custompackages'
          title={
            <div>
              Custom Packages {' '}
              {/* <span className='d-inline-block' data-toggle='tooltip' title='L1 Categories'>
                <i className='bi bi-info-circle-fill'></i>
              </span> */}
            </div>
          }
          hasBullet={true}
        />


      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/catalogs/products' title='Products' hasBullet={false}>
        <AsideMenuItem to='/catalogs/products' title='Products' hasBullet={true} />
        <AsideMenuItem
          to='/catalogs/product-categories'
          title={
            <div>
              Categories{' '}
              <span className='d-inline-block' data-toggle='tooltip' title='L3 categories'>
                <i className='bi bi-info-circle-fill'></i>
              </span>
            </div>
          }
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      

    </>

    


  )
}



{/* <AsideMenuItemWithSub to='/catalogs/products' title='products' hasBullet={false}>
        <AsideMenuItem to='/catalogs/products' title='Products' hasBullet={true} />
        <AsideMenuItem
          to='/catalogs/variation'
          title={
            <div>
              Variation{' '}
              <span className='d-inline-block' data-toggle='tooltip' title='L3 categories'>
                <i className='bi bi-info-circle-fill'></i>
              </span>
            </div>
          }
          hasBullet={true}
        />
      </AsideMenuItemWithSub> */}


{/* </AsideMenuItemWithSub> */ }
{/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Request'
        // icon='profile-circle'
        // fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Publish' hasBullet={false} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}
{/* <AsideMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
{/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-11'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}
{/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
{/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
{/* <AsideMenuItem
        to='/apps/user-management/users'
        icon='shield-tick'
        title='User management'
        fontIcon='bi-layers'
      /> */}

