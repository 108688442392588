import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../modules/users/ProfilePage'))
  const PackagePage = lazy(() => import('../modules/package/PackagePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const OMSPage = lazy(() => import('../modules/oms'))
  const HpmsPages = lazy(() => import('../modules/hmps'))
  const HppmsPages = lazy(() => import('../modules/hppms'))
  const CmsPages = lazy(() => import('../modules/cms'))
  const EmployersPage = lazy(() => import('../modules/employers'))
  const VmpsPages = lazy(() => import('../modules/vpms'))
  const TSCpages = lazy(() => import('../modules/tsc'))
  const TAXpages = lazy(() => import('../modules/tax'))



  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/oms' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='catalogs/*'
          element={
            <SuspensedView>
              <PackagePage />
            </SuspensedView>
          }
        />
        <Route
          path='Employers/*'
          element={
            <SuspensedView>
              <EmployersPage />
            </SuspensedView>
          }
        />
        <Route
          path='oms/*'
          element={
            <SuspensedView>
              <OMSPage />
            </SuspensedView>
          }
        />
        <Route
          path='hpms/*'
          element={
            <SuspensedView>
              <HpmsPages />
            </SuspensedView>
          }
        />
        <Route
          path='hppms/*'
          element={
            <SuspensedView>
              <HppmsPages />
            </SuspensedView>
          }
        />
        <Route
          path='cms/*'
          element={
            <SuspensedView>
              <CmsPages />
            </SuspensedView>
          }
        />
        <Route
          path='vpms/*'
          element={
            <SuspensedView>
              <VmpsPages />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/*'
          element={
            <SuspensedView>
              <TSCpages />
            </SuspensedView>
          }
        />
         <Route
          path='billing/*'
          element={
            <SuspensedView>
              <TAXpages />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
