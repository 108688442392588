import React from 'react'
import { AsideMenuItem } from './AsideMenuItem'
export function TAXAsideMenuMain() {
  return (
    <>
      <AsideMenuItem to='/billing' icon='element-11' title={'Billings'} fontIcon='bi-people-fill' />
       <div className='separator my-2'></div>
       <AsideMenuItem state={''} to='/billing/tax' title='TAX' hasBullet={false} />
    </>
  )
}
