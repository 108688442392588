// /* eslint-disable react/jsx-no-target-blank */
// import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function HPMSAsideMenuMain() {

  const authData = JSON.parse(localStorage.getItem('auth') || 'null')
  const roles = authData?.role?.split(',').map((role: string) => role.trim()) || []

  const isSuperUser = roles.includes('superuser')
  const isSellerOrPartner = roles.some(role => ['seller-vp', 'partner'].includes(role))

  if (isSellerOrPartner) {
    return (
      <>
     <AsideMenuItem to='/hpms' icon='element-11' title={'HPMS'} fontIcon='bi-people-fill' />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/hpms/overview' title='Overview' hasBullet={false} />
    
      </>
    )
  }

  // const intl = useIntl()

  return (
    <>
      <AsideMenuItem to='/hpms' icon='element-11' title={'HPMS'} fontIcon='bi-people-fill' />
      <div className='separator my-2'></div>
      {/* <AsideMenuItemWithSub to='/users' title='Users' hasBullet={false}> */}
      <AsideMenuItem state={''} to='/hpms/overview' title='Overview' hasBullet={false} />
      <AsideMenuItemWithSub to='' title='Healthcare Professionals ' hasBullet={false}>
        <AsideMenuItem
          state={{user_role: 'nurse'}}
          to='/users/nurses'
          title='Nurses'
          hasBullet={true}
        />{' '}
      </AsideMenuItemWithSub>
      <AsideMenuItem
        state={''}
        to='/hpms/bgv-requests'
        title='Background Verifications'
        hasBullet={false}
      />
    </>
  )
}
