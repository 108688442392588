import {createAsyncThunk} from '@reduxjs/toolkit'
import {getApi, patchApi, postApi} from '../../../axios'
import {
  CREATE_PACKAGE,
  PACKAGE,
  CUSTOMPACKAGELIST,
  PACKAGE_CATEGORY,
  PACKAGE_DETAILS,
  SERVICE_TYPE,
  SERVICE_TYPES,
  PRODUCTS,
  PRODUCT_CATEGORY,
  CREATECUSTOMPACKAGE,
  GETALL_USER,VARIATION
} from '../../../axios/services'
import {API_ERROR} from '../../../global'
import {toast as message} from 'react-toastify'

export const getPackageList = createAsyncThunk(
  'catalogs/getPackageList',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await getApi(
        `${PACKAGE}?healon_services_type=${payload?.id || ''}&coms=true&page=${payload?.page || 1}`
      )
      return response?.data
    
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getCustompacakgelist = createAsyncThunk(
  'catalogs/getCustompacakgelist',
  async (payload = {}, thunkAPI) => {
    try {
      const response = await getApi(
        `${CUSTOMPACKAGELIST}?page=${payload?.page || 1}&items_per_page=10`
      )
      console.log(response?.data,'555555555555555555')
      return response?.data
    
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getPackageDetails = createAsyncThunk(
  'catalogs/getPackageDetails',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(PACKAGE_DETAILS + payload)
      if (response?.data?.success) {
        return response?.data?.data[0]
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
export const postcustompacakge = createAsyncThunk(
  'catalogs/postcustompacakge',
  async ({body, next = () => {}}, thunkAPI) => {
    console.log(body,'999999999999999')
    try {
      const response = await postApi(CREATECUSTOMPACKAGE, body)
      console.log(body,'10101010101')

      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchcustompackage = createAsyncThunk(
  'catalogs/patchcustompackage',
  async ({body, next = () => {}}, thunkAPI) => {
    console.log(body,'999999999999999')
    try {
      const response = await patchApi(CREATECUSTOMPACKAGE, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const postPackage = createAsyncThunk(
  'catalogs/postPackage',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await postApi(CREATE_PACKAGE, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)


export const patchPackage = createAsyncThunk(
  'catalogs/patchPackage',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(CREATE_PACKAGE, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)


export const getPackageCategory = createAsyncThunk(
  'catalog/getPackageCategory',
  async (_, thunkAPI) => {
    try {
      const response = await getApi(PACKAGE_CATEGORY)
      if (response?.data?.success) {
        console.log(response?.data, 'iefoefh')
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

// ===============================================================

export const getCareTypes = createAsyncThunk(
  'catalogs/getCareTypes',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(SERVICE_TYPES + payload)
      console.log(response?.data)
      return response?.data?.data
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const postCareType = createAsyncThunk(
  'catalogs/postCareType',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await postApi(SERVICE_TYPES, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchCareType = createAsyncThunk(
  'catalogs/patchCareType',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(SERVICE_TYPES, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getCareCategories = createAsyncThunk(
  'catalogs/getCareCategories',
  async (payload, thunkAPI) => {
    try {
      const response = await getApi(SERVICE_TYPE + payload)
      console.log(response?.data)
      return response?.data?.data
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const postCareCategory = createAsyncThunk(
  'catalogs/postCareCategory',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await postApi(SERVICE_TYPE, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchCareCategory = createAsyncThunk(
  'catalogs/patchCareCategory',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(SERVICE_TYPE, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
//====================================================================//

export const getProducts = createAsyncThunk('catalogs/getProducts', async (payload, thunkAPI) => {
  try {
    const response = await getApi(`${PRODUCTS}?page=${payload?.page || 1}&items_per_page=10`)
    if (response?.data?.success) {
      console.log(response?.data.data.products,'5555555555555555555555')
      return response
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

//====================================================================//

export const postvariation = createAsyncThunk(
  'catalogs/postvariation',
  async ({body, next = () => {}}, thunkAPI) => {
    console.log(body,'55555555555555')
    try {
      const response = await postApi(VARIATION, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)


export const getVariations = createAsyncThunk('catalogs/getVariations', async (payload, thunkAPI) => {
  try {
    const response = await getApi(`${VARIATION}?page=${payload?.page || 1}&items_per_page=10`)
    if (response?.data?.success) {
    console.log(response?.data,'999999999999)')
      return response?.data
    }
  } catch (error) {
    message.error(error?.response?.data?.message || API_ERROR)
    return thunkAPI.rejectWithValue(error.response.data.message)
  }
})

//====================================================================//


export const postProduct = createAsyncThunk(
  'catalogs/postProduct',
  async ({body, next = () => {}}, thunkAPI) => {
    console.log(body,'llllllllllllllllllllllllll')

    try {
      const response = await postApi(PRODUCTS, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
        return response?.data
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)
export const patchProduct = createAsyncThunk(
  'catalogs/patchProduct',
  async ({body, next = () => {}}, thunkAPI) => {
    
    try {
      const response = await patchApi(PRODUCTS, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next()
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const getProductCategories = createAsyncThunk(
  'catalogs/getProductCategories',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(`${PRODUCT_CATEGORY}?page=${payload?.page || 1}&items_per_page=10`)
      console.log(response?.data)
      return response?.data
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const postProductCategory = createAsyncThunk(
  'catalogs/postProductCategory',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await postApi(PRODUCT_CATEGORY, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)

export const patchProductCategory = createAsyncThunk(
  'catalogs/patchProductCategory',
  async ({body, next = () => {}}, thunkAPI) => {
    try {
      const response = await patchApi(PRODUCT_CATEGORY, body)
      if (response?.data?.success) {
        message.success(response?.data?.message)
        next(response?.data?.data)
      }
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)


export const getalluser = createAsyncThunk(
  'catalogs/getalluser',
  async (payload = '', thunkAPI) => {
    try {
      const response = await getApi(GETALL_USER + payload)
      console.log(response?.data,'44444444444444')
      return response?.data
    } catch (error) {
      message.error(error?.response?.data?.message || API_ERROR)
      return thunkAPI.rejectWithValue(error.response.data.message)
    }
  }
)